import React, { Component } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Lightbox from 'react-images'
import Footer from '../components/footer'
import Loader from 'react-loader-spinner'

import {
  theme,
  StyledGrid,
  Box,
  ClickArea,
  StyledImg,
  StyledLoader,
} from '../components/galleryStyles'

class GalleryPageTemplate extends Component {
  state = {
    lightboxIsOpen: false,
    currentImage: 0,
    images: [],
  }
  openLightbox = (index, event) => {
    event.preventDefault()
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    })
  }
  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    })
  }
  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }
  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }

  componentDidMount = () => {
    const images = this.props.data.contentfulGalleryPage.gallery.images.sort(
      () => Math.random() - 0.5
    )
    this.setState({ images: images })
  }

  render() {
    const images = this.state.images.map(img => ({
      src: img.fixed.src,
      srcSet: img.fixed.srcSet,
    }))
    return (
      <>
        <SEO
          title={this.props.data.contentfulGalleryPage.title}
          keywords={['gatsby', 'application', 'react']}
        />
        {this.state.images.length === 0 ? (
          <>
            <StyledLoader>
              <Loader type="Triangle" color="#000" height="80" width="80" />
            </StyledLoader>
          </>
        ) : (
          <>
            <StyledGrid>
              {this.state.images.map((img, i) => (
                <Box key={img.id}>
                  <ClickArea onClick={e => this.openLightbox(i, e)}>
                    <StyledImg fluid={img.fluid} />
                  </ClickArea>
                </Box>
              ))}
            </StyledGrid>
            <Lightbox
              currentImage={this.state.currentImage}
              images={images}
              isOpen={this.state.lightboxIsOpen}
              onClickImage={this.closeLightbox}
              onClickPrev={this.gotoPrevious}
              onClickNext={this.gotoNext}
              onClose={this.closeLightbox}
              backdropClosesModal={true}
              width={2560}
              theme={theme}
            />
            <Footer />
          </>
        )}
      </>
    )
  }
}

export default GalleryPageTemplate

export const query = graphql`
  query GalleryPageBySlug($slug: String!) {
    contentfulGalleryPage(slug: { eq: $slug }) {
      id
      title
      gallery {
        images {
          id
          fixed(width: 1000) {
            srcSet
            src
          }
          fluid(maxWidth: 1000) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
